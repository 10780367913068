/**
 * Set up the “Priority+” navigation for the desktop menu.
 */
(($) => {
	const nav = $('#main-nav');
	const vlinks =
		$('#main-nav .navigation-menu.navigation-visible');
	const hlinks =
		$('#main-nav .navigation-menu.navigation-more');
	const hlinksmore =
		$('#main-nav .navigation-menu.navigation-more .navigation-sub-menu');

	const breaks = [];

	function updateNav() {
		let availableSpace =
			hlinks.hasClass('is-hidden') ? nav.width() : nav.width() - hlinks.width();

		const width = vlinks.width();
		const margin = 2;

		// The visible list is overflowing the nav
		if (width >= (availableSpace - margin)) {
			// Record the width of the list
			breaks.push(width);

			// Move item to the hidden list
			vlinks
				.children()
				.last()
				.removeClass('navigation-item')
				.addClass('navigation-sub-item')
				.prependTo(hlinksmore);

			// Show the dropdown btn
			if (hlinks.hasClass('is-hidden')) {
				hlinks.addClass('is-visible');
				hlinks.removeClass('is-hidden');
			}

		// The visible list is not overflowing
		} else {
			// There is space for another item in the nav
			if (availableSpace > breaks[breaks.length - 1]) {
				// Move the item to the visible list
				hlinksmore
					.children()
					.first()
					.addClass('navigation-item')
					.removeClass('navigation-sub-item')
					.appendTo(vlinks);
				breaks.pop();
			}

			// Hide the dropdown btn if hidden list is empty
			if (breaks.length < 1) {
				hlinks.addClass('is-hidden');
				hlinks.removeClass('is-visible');
			}
		}

		availableSpace =
			hlinks.hasClass('is-hidden') ? nav.width() : nav.width() - hlinks.width();

		// Recur if the visible list is still overflowing the nav
		if (width > availableSpace) {
			updateNav();
		}
	}

	// Add a window listeners
	window.onresize = () => {
		if ($(window).width() >= 720) {
			updateNav();
		} else if (breaks.length) {
			// Move the item to the visible list
			hlinksmore
				.children()
				.first()
				.addClass('navigation-item')
				.removeClass('navigation-sub-item')
				.appendTo(vlinks);

			breaks.pop();

			// Hide the dropdown btn if hidden list is empty
			if (breaks.length < 1) {
				hlinks.removeClass('is-visible');
				hlinks.addClass('is-hidden');
			}
		}
	};

	// Perform an initial run.
	if ($(window).width() >= 720) {
		updateNav();
	}
})(jQuery);
