/*
 * Mobile menu code in here
 */

/**
 * Set up the responsive/mobile navigation and search toggle buttons.
 */

(() => {
	const html = document.documentElement;
	html.classList.add('js');
	let animateTimeout;

	const navigationToggle = document.getElementById('navigation-toggle');
	const navigation = document.getElementById('main-nav');
	const navigationBackdrop = document.createElement('div');
	navigationBackdrop.id = 'navigation-backdrop';
	navigationBackdrop.className = 'navigation-backdrop fade';

	const search = document.getElementById('search');
	const searchToggle = document.getElementById('search-toggle');

	const navigationToggleCheck = (event) => {
		event.preventDefault();

		if (navigation.classList.contains('is-active')) {
			html.classList.remove('has-active-modal');
			navigation.classList.remove('is-active');
			navigation.classList.add('is-animating');
			navigationBackdrop.removeEventListener('click', navigationToggleCheck);
			document.getElementById('navigation-backdrop').classList.remove('in');

			clearTimeout(animateTimeout);

			animateTimeout = setTimeout(() => {
				navigation.classList.remove('is-animating');
				navigation.parentNode.removeChild(navigationBackdrop);
			}, 250);
		} else {
			html.classList.add('has-active-modal');
			navigation.classList.add('is-active');
			navigation.classList.add('is-animating');
			navigation.parentNode.insertBefore(
				navigationBackdrop,
				navigation.nextSibling);

			setTimeout(() => {
				navigationBackdrop.classList.add('in');
				navigationBackdrop.addEventListener(
					'click',
					navigationToggleCheck);
			}, 1);

			clearTimeout(animateTimeout);

			animateTimeout = setTimeout(() => {
				navigation.classList.remove('is-animating');
			}, 250);

			if (
				searchToggle !== undefined
				&& searchToggle !== null
				&& search !== undefined
				&& search !== null
			) {
				if (search.classList.contains('is-active')) {
					searchToggle.dispatchEvent(new Event('click'));
				}
			}
		}
	};

	if (navigationToggle !== undefined && navigationToggle !== null) {
		navigationToggle.addEventListener('click', navigationToggleCheck);
	}

	if (searchToggle !== undefined && searchToggle !== null) {
		searchToggle.addEventListener('click', (event) => {
			event.preventDefault();

			if (search.classList.contains('is-active')) {
				html.classList.remove('has-active-modal');
				search.classList.remove('is-active');
				search.classList.add('is-animating');

				clearTimeout(animateTimeout);

				animateTimeout = setTimeout(() => {
					search.classList.remove('is-animating');
					search.classList.remove('is-visible');
				}, 250);
			} else {
				html.classList.add('has-active-modal');
				search.classList.add('is-visible');
				search.classList.add('is-animating');

				setTimeout(() => {
					search.classList.add('is-active');
				}, 1);

				clearTimeout(animateTimeout);

				animateTimeout = setTimeout(() => {
					search.classList.remove('is-animating');
				}, 250);

				if (
					navigationToggle !== undefined
					&& navigationToggle !== null
					&& navigation !== undefined
					&& navigation !== null
				) {
					if (navigation.classList.contains('is-active')) {
						navigationToggle.dispatchEvent(new Event('click'));
					}
				}
			}
		});
	}
})();
