/**
 * Add a hint of dynamic to the APNIC banner glyph.
 */

(() => {
	const glyph = document.getElementById('glyph');
	if (glyph !== undefined && glyph !== null) {
		const colors = ['blue', 'darkgreen', 'lightgreen', 'orange', 'purple'];
		const position = Math.floor(Math.random() * 5);
		const className = `header-glyph-${colors[Math.floor(Math.random() * colors.length)]}-${position}`;
		glyph.className += ` ${className}`;
	}
})();
